import React, { ReactNode } from 'react'
import Head from 'next/head'
import { Box, Link, Container } from '@material-ui/core'
import Header from './Header'
import Footer from './Footer'

const GoogleAds = ({ show }: { show: boolean }) => (
  <>
    <script
      async
      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
    ></script>
    <script
      dangerouslySetInnerHTML={{
        __html: `
(adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=${show ? 0 : 1};

if (!adsbygoogle.loaded) {
  adsbygoogle.push({
    google_ad_client: '${process.env.NEXT_PUBLIC_ADSENSE_ID}',
    enable_page_level_ads: true
  });
}
  `,
      }}
    />
  </>
)

interface Props {
  children: ReactNode
  hideHeader?: boolean
  masthead?: ReactNode
  maxWidth?: 'lg' | false
  showAds?: boolean
}

const Layout = ({
  children,
  hideHeader = false,
  masthead,
  maxWidth = false,
  showAds = false,
}: Props) => {
  return (
    <>
      <Head>
        <GoogleAds show={showAds} />
      </Head>
      <Box>
        <Box
          position="absolute"
          left={-10000}
          top="auto"
          width="1px"
          height="1px"
          overflow="hidden"
        >
          <Link href="#skip-heading">Skip Main Navigation</Link>
        </Box>

        {!hideHeader && <Header />}

        {masthead}

        <Container maxWidth={maxWidth}>
          <Box
            component="main"
            minHeight={!masthead ? 'calc(100vh - 60px)' : undefined}
            id="skip-heading"
          >
            {children}
          </Box>
        </Container>

        <Box mt={4}>
          <Footer />
        </Box>
      </Box>
    </>
  )
}

export default Layout
