interface Props {
  className?: string
  fill?: string
}

const Logo = ({ className, fill = 'currentcolor' }: Props) => (
  <svg viewBox="0 0 496 496" className={className} fill={fill}>
    <path d="M395.576 160.84C368.696 147.408 352 120.392 352 90.336v-71.28L313.888 0h-12.312l-2.016 1.344C299.344 1.488 277.24 16 248 16c-29.024 0-51.352-14.52-51.56-14.656L194.424 0h-12.312L144 19.056v71.28c0 30.056-16.696 57.072-43.576 70.504L96 163.056V496h304V163.056l-4.424-2.216zM248 32c26.488 0 47.552-10.008 56.16-14.808-.752 16.968-7.928 56.952-56.16 85.584-48.664-28.856-55.608-68.68-56.232-85.624C200.336 21.936 221.44 32 248 32zM128 480h-16V172.8c5.84-3.304 11.096-7.312 16-11.64V480zm224 0H144V142.848c10.16-15.24 16-33.304 16-52.512V28.944l15.968-7.984c1.592 22.424 12.24 66.976 68.144 98.032l3.888 2.16 3.888-2.16c55.904-31.056 66.552-75.608 68.144-98.032L336 28.944v61.392c0 19.208 5.84 37.272 16 52.512V480zm32 0h-16V161.16c4.904 4.328 10.16 8.328 16 11.64V480z" />
    <path d="M201.48 202.6c.8-.4 1.667-.6 2.6-.6h10.8c1.334 0 2 .667 2 2v136c0 1.333-.666 2-2 2h-10.2c-1.333 0-2-.667-2-2V218.8c0-.267-.133-.467-.4-.6-.133-.133-.333-.133-.6 0l-18.8 6.2-.6.2c-.933 0-1.533-.6-1.8-1.8l-1-7.4v-.4c0-.667.467-1.333 1.4-2l20.6-10.4zM272.852 344c-10.133 0-18.333-3.267-24.6-9.8-6.133-6.667-9.2-15.333-9.2-26v-72.4c0-10.667 3.067-19.267 9.2-25.8 6.267-6.667 14.467-10 24.6-10 10.267 0 18.534 3.333 24.8 10 6.267 6.533 9.4 15.133 9.4 25.8v72.4c0 10.667-3.133 19.333-9.4 26-6.266 6.533-14.533 9.8-24.8 9.8zm0-12.4c6.134 0 11-2.067 14.6-6.2 3.6-4.133 5.4-9.6 5.4-16.4v-74c0-6.8-1.8-12.267-5.4-16.4-3.6-4.133-8.466-6.2-14.6-6.2-5.866 0-10.6 2.067-14.2 6.2-3.6 4.133-5.4 9.6-5.4 16.4v74c0 6.8 1.8 12.267 5.4 16.4 3.6 4.133 8.334 6.2 14.2 6.2z" />
  </svg>
)

export default Logo
