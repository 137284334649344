import Link from 'next/link'
import {
  Box,
  Typography,
  Link as MuiLink,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { site } from '../config/main.json'

const useStyles = makeStyles(({ typography }) =>
  createStyles({
    logoLink: {
      fontFamily: `'Bebas Neue', ${typography.fontFamily}`,
    },
  }),
)

const Footer = () => {
  const classes = useStyles()
  const curYear = new Date().getFullYear()
  const copyright = `${site.since}${
    curYear > site.since ? ` — ${curYear}` : ''
  }`

  return (
    <Box
      component="footer"
      borderTop={1}
      borderColor="divider"
      py={4}
      px={{ xs: 2, sm: 3 }}
    >
      <Box textAlign="center">
        <Link href="/" passHref>
          <MuiLink
            color="textSecondary"
            variant="h2"
            underline="none"
            className={classes.logoLink}
          >
            NBA Player Tiers
          </MuiLink>
        </Link>
      </Box>
      <Box mt={4}>
        <Typography variant="body1" align="center">
          Inspired by the{' '}
          <MuiLink
            href="https://twitter.com/FTFonFS1/status/1341468886114447365"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            NBA Player Pyramid
          </MuiLink>{' '}
          by{' '}
          <MuiLink
            href="https://twitter.com/getnickwright"
            target="_blank"
            rel="Noopener noreferrer nofollow"
          >
            Nick Wright
          </MuiLink>
          . NBA Logo and player photos courtesy of{' '}
          <MuiLink
            href="https://nba.com"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            nba.com
          </MuiLink>
          .
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" align="center">
          © {copyright},{' '}
          <MuiLink
            href="https://www.benmvp.com"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
            underline="always"
          >
            Ben Ilegbodu
          </MuiLink>
          . All rights reserved.{' '}
          <MuiLink
            href="https://www.biblegateway.com/passage/?search=2%20cor%205%3A17&version=NLT;NTV"
            target="_blank"
            rel="noopener noreferrer nofollow"
            color="inherit"
          >
            2 Cor 5:17
          </MuiLink>
          .{' '}
          <Link href="/sitemap" passHref>
            <MuiLink color="inherit">Sitemap</MuiLink>
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer
