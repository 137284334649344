import Head from 'next/head'
import { useTheme } from '@material-ui/core'
import { site } from '../config/main.json'
import { getFullUrl } from '../utils'

type MetaTag =
  | { property: string; content: string; name?: undefined }
  | { name: string; content: string; property?: undefined }

interface Props {
  description?: string
  imageAlt?: string
  imageUrl?: string
  meta?: MetaTag[]
  schemaOrg?: Record<string, unknown>
  shortDescription?: string
  title?: string
  type?: string
  url: string
}

const Meta = ({
  description = site.description,
  meta,
  title,
  imageAlt = title || site.name,
  imageUrl = site.image,
  schemaOrg,
  shortDescription = description,
  type = 'website',
  url,
}: Props) => {
  const { palette } = useTheme()
  const fullTitle = title ? `${title} | ${site.name}` : site.title
  const fullImageUrl = imageUrl.startsWith('http')
    ? imageUrl
    : getFullUrl(imageUrl)
  const siteSchemaOrg = {
    '@context': 'http://schema.org/',
    url,
    ...(schemaOrg || {
      '@type': 'WebSite',
      name: fullTitle,
      alternateName: site.name,
      copyrightHolder: site.owner.name,
      copyrightYear: site.since,
      description,
      image: fullImageUrl,
    }),
  }

  return (
    <Head>
      <title>{fullTitle}</title>

      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />

      {/* General tags */}
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="image" content={fullImageUrl} />

      <link rel="shortcut icon" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="apple-mobile-web-app-title" content={site.name} />
      <meta name="application-name" content={site.name} />
      <meta name="msapplication-TileColor" content={palette.secondary.main} />
      <meta name="theme-color" content={palette.background.default} />

      {/* OpenGraph tags */}
      <meta property="og:site_name" content={site.name} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={fullImageUrl} />
      <meta property="og:image:alt" content={imageAlt} />
      <meta property="og:locale" content="en_US" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={shortDescription} />
      <meta name="twitter:image" content={fullImageUrl} />
      <meta name="twitter:image:alt" content={imageAlt} />

      {/* Additional tags */}
      {meta &&
        meta.map((metaInfo) => (
          <meta
            key={`${metaInfo.name || metaInfo.property}${metaInfo.content}`}
            {...metaInfo}
          />
        ))}

      {/* schema.org */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(siteSchemaOrg),
        }}
      />
    </Head>
  )
}

export default Meta
