import Link from 'next/link'
import {
  Box,
  Link as MuiLink,
  makeStyles,
  createStyles,
  Button,
} from '@material-ui/core'
import Logo from './Logo'

const useStyles = makeStyles(({ palette, spacing, typography }) =>
  createStyles({
    header: {
      alignItems: 'center',
      backgroundColor: palette.secondary.dark,
      borderBottom: `1px solid ${palette.divider}`,
      color: palette.getContrastText(palette.secondary.dark),
      display: 'flex',
      height: 60,
      justifyContent: 'space-between',
      padding: spacing(1),
    },
    logo: {
      height: 44,
      width: 44,
    },
    logoLink: {
      alignItems: 'center',
      display: 'flex',
      fontFamily: `'Bebas Neue', ${typography.fontFamily}`,
    },
  }),
)

const Header = () => {
  const classes = useStyles()

  return (
    <Box component="header" className={classes.header}>
      <Link href="/" passHref>
        <MuiLink
          variant="h4"
          underline="none"
          color="inherit"
          className={classes.logoLink}
        >
          <Logo className={classes.logo} />
          NBA Player Tiers
        </MuiLink>
      </Link>

      <Link href="/create" passHref>
        <Button variant="outlined" color="inherit">
          Create
        </Button>
      </Link>
    </Box>
  )
}

export default Header
