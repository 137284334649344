import { site } from '../config/main.json'

const ROOT_URL =
  process.env.NEXT_PUBLIC_VERCEL_URL &&
  process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' &&
  `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
const SITE_URL = ROOT_URL || site.url

export const getFullUrl = (path = '') => `${SITE_URL}${path}`
export const formatDate = (date: string) =>
  new Date(date).toLocaleDateString(undefined, {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  })

type FillFn<FillType> = (index: number) => FillType
export const createFillArray = <ReturnType>(
  length: number,
  fillFn: FillFn<ReturnType>,
) => Array.from({ length }, (_, index) => fillFn(index))
